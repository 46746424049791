// let _partnerDomain = null;

const _postMessage = (data) => {
  console.log("postmess", data);
  if (window.self !== window.top) {
      window.top.postMessage(data,"*");
  }
}

// export const setPartnerData = (partnerDomain) => {
//   _partnerDomain = partnerDomain;
// }


export default {
  postMessage: function(data, domain) {
    window.top.postMessage(data, domain);
  },
  checkoutComplete: (orderReferences) => {
    _postMessage({
      type: "checkoutComplete",
      value: orderReferences
    });
  },
  checkoutRedirect: (orderReferences) => {
    _postMessage({
      type: "checkoutRedirect",
      value: orderReferences
    })
  },
  checkoutCancel: (orderReferences) => {
    _postMessage({
      type: "checkoutCanceled",
      value: orderReferences
    });
  },
  scrollToTop: () => {
    _postMessage({
      type: "scrollToTop",
      value: ""
    });
  },

  resize: height => {
    _postMessage({
      type: "resize",
      value: height
    });
  },
};
